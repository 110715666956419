import axios from 'axios';

export const fetchNetworkData = async () => {
  try {
    const fetchUsersUrl = process.env.REACT_APP_FETCH_USERS_URL || '';
    const response = await axios.get(fetchUsersUrl);
    return response?.data;
  } catch (error) {
    console.log('Error fetching data:', error);
    return error;
  }
};
