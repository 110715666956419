import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MenuIcon from '@mui/icons-material/Menu';

const DrawerWrapper = styled(Drawer)(({theme}) => ({
  '&&' : {
    width: '11rem',
    flexShrink: 0,
    zIndex: 1,

    '@media (max-width: 850px)' : {
      width: '100%',
    }
  },
  '&& .MuiPaper-root.MuiPaper-elevation' : {
    border: 'none',
    width: '11rem',
    background: theme.palette.primary.main,
  }
}))

const StyledIconButton = styled(IconButton)({
  padding: '1.3rem 0rem 0rem 2rem !important',
  '&& svg path' : {
    fill: 'white',
  },
  '@media (max-width: 600px)' : {
    padding: '1.2rem 0rem 0rem 1.8rem !important',
  },

  '@media (max-width: 500px)' : {
    padding: '1rem 0rem 0rem 1.5rem !important',
  }
})

const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 850px)');

  const navRoutes = [
    {
      key: 'NetworkData',
      title: 'Network Data',
      icon: <QueryStatsIcon />,
      path: '/',
    },
    {
      key: 'Restricted Domains',
      title: 'Restricted Domains',
      icon: <CorporateFareIcon />,
      path: '/domain',
    },
  ];

  const pathNameHandler = (loc: string) => {
    return location.pathname === loc;
  };

  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      {isMobile && (
        <StyledIconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerToggle}
          edge='start'
          sx={{ position: 'fixed', top: 0, zIndex: 2 }}
        >
          <MenuIcon />
        </StyledIconButton>
      )}
      <DrawerWrapper
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? open : true}
      >
        <Toolbar />
        <List style={{ paddingTop: '0px' }}>
          {navRoutes?.map((itm: any) => (
            <ListItem
              key={itm.key}
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle(); // Close the drawer on mobile when a link is clicked
                }
                navigate(itm.path);
              }}
              sx={{
                backgroundColor: pathNameHandler(itm.path)
                  ? 'white'
                  : 'transparent',
                cursor: 'pointer',
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '30px',
                  color: pathNameHandler(itm.path) ? 'black' : 'white',
                }}
              >
                {itm.icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: pathNameHandler(itm.path) ? 'black' : 'white',
                  cursor: 'pointer',
                }}
                primary={itm.title}
              />
            </ListItem>
          ))}
        </List>
      </DrawerWrapper>
    </>
  );
};

export default SideNav;
