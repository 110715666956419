import { useEffect } from 'react';
import NProgress from 'nprogress';

const LoadingScreen = () => {
    
  useEffect(() => {
    NProgress.start();
    return (): void => {
      NProgress.done();
    };
  }, []);

  return <div style={{ minHeight: '100%' }} />;
};

export default LoadingScreen;
