import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography } from '@mui/material';
import SearchComponent from './Search';

interface TopNavProps {
  userData: any;
}

const AppBarWrapper = styled(AppBar)({
  boxShadow: 'none !important',
  height: '4rem',
});

const ToolbarWrapper = styled(Toolbar)({
  '@media (max-width: 600px)': {
    margin: '0rem 2rem',
    padding: 0,
  },
});

const DrawerBoxLogo = styled(Typography)({
  margin: '2rem 0rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#203064',
});

const Icon = styled('img')({
  width: '240px',
  height: '60px',
  paddingRight: '5px',
  background: '#203064',

  '@media (max-width: 500px)': {
    width: '11rem',
    height: '2.8rem',
  },

  '@media (max-width: 400px)': {
    width: '9rem',
    height: '2.5rem',
  },
});

const TopNav: React.FC<TopNavProps> = ({ userData }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const searchChangeHandler = (event: any) => {
    setSearchValue(event.target.value);
  };

  return (
    <div style={{ width: '100%' }}>
      <AppBarWrapper
        position='fixed'
        sx={{ background: '#203064', zIndex: 1 }}
      >
        <ToolbarWrapper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0rem 2rem',
          }}
        >
          <DrawerBoxLogo variant='h6'>
            <Icon src='/logo.png' alt='moitele' />
          </DrawerBoxLogo>
          <SearchComponent searchChangeHandler={searchChangeHandler} />
        </ToolbarWrapper>
      </AppBarWrapper>
      <main style={{ flexGrow: 1 }}>
        <Toolbar />
        <Outlet context={[searchValue, userData]} />
      </main>
    </div>
  );
};

export default TopNav;
