import axios from 'axios';

export const fetchDomain = async () => {
  try {
    const fetchDomainUrl = process.env.REACT_APP_FETCH_DOMAIN_URL ? process.env.REACT_APP_FETCH_DOMAIN_URL : '';
    const response = await axios.get(fetchDomainUrl);
    return response?.data;
  } catch (error) {
    console.log('Error fetching data:', error);
    return error;
  }
};

export const addDomain = async (org: string[]) => {
  try {
    const addDomainUrl = process.env.REACT_APP_ADD_DOMAIN_URL
      ? process.env.REACT_APP_ADD_DOMAIN_URL
      : '';
    const listOfDomains = org.map((item) => ({
      domain: item,
    }));
    const response = await axios.post(addDomainUrl, listOfDomains);
    return response?.data;
  } catch (error) {
    console.log('Error adding data:', error);
    return error;
  }
};
