import { useEffect, useState } from 'react';
import { fetchNetworkData } from '../utils/fetchNetworkData';
import SideNav from './SideNav';
import TopNav from './TopNav';

const Nav = () => {
  const [userData, setUserData] = useState([]);

  const fetchUserDetails = async () => {
    const data = await fetchNetworkData();
    if (data) {
      setUserData(data);
    } else {
      setUserData([]);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <SideNav />
      <TopNav userData={userData}/>    
    </div>
  );
};

export default Nav;
