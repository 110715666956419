import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';

interface SearchProps {
  searchChangeHandler: (event: any) => void;
}

const Search = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '@media (max-width: 600px)': {
    marginLeft: '8px',
    width: 'auto',
  },
});

const SearchIconWrapper = styled('div')({
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media (max-width: 500px)': {
    width: '1rem',
    height: '1.5rem',
  },
  '@media (max-width: 400px)': {
    width: '1rem',
    height: '1.3rem',
  },
});

const StyledInputBase = styled(InputBase)({
  color: 'white',

  '& .MuiInputBase-input' : {
    color: 'white',
    padding: '8px',
    transition: 'width 0.3s ease',
    width: '100%',

    '@media (min-width: 600px)' : {
      width: '96px',
     ' &:focus' : {
        width: '160px'
      }
    },

    '@media (max-width: 500px)' : {
      width: '8rem',
    },

    '@media (max-width: 400px)' : {
      width: '5rem',
    },
  }
})

const SearchComponent: React.FC<SearchProps> = ({ searchChangeHandler }) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon sx={{ color: 'white' }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder='Search…'
        inputProps={{ 'aria-label': 'search' }}
        onChange={searchChangeHandler}
      />
    </Search>
  );
};

export default SearchComponent;
