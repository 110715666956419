import { useRoutes } from 'react-router-dom';
import routes from './routes/Routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#203064',
    },
  },
});

const App = () => {
  const content = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      {content}
    </ThemeProvider>
  );
};

export default App;
