import React from 'react';
import { styled } from '@mui/material/styles';
import { Clear as ClearIcon, Add as AddIcon } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';

interface MultipleInputProps {
  inputValues: string[];
  newInput: string;
  setNewInput: (newInput: string) => void;
  setInputValues: (inputValue: string[]) => void;
}

const InputDiv = styled('div')(({ theme }) => ({
  border: '1px solid black',
  width: 'fit-content',
  borderRadius: '5px',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  color: 'white',
  gap: '0.5rem',
  justifyContent: 'space-between',
  padding: '0.2rem 0.25rem',
  alignItems: 'center',
}));

const TextFieldWrapper = styled(TextField)(({ theme }) => ({
  '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
    {
      borderColor: theme.palette.primary.main,
    },
}));

const InputFieldDiv = styled('div')({
  display: 'flex',
  gap: '2rem',
})
  
const MultipleInputsDiv = styled('div')({
  display: 'flex',
  gap: '0.5rem',
  flexWrap: 'wrap',
})

const AddingInputDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
})

const MultipleInputs: React.FC<MultipleInputProps> = ({
  inputValues,
  setInputValues,
  newInput,
  setNewInput,
}) => {
  const handleAddInput = () => {
    if (newInput.trim() !== '') {
      if( inputValues?.length) {
      setInputValues([...inputValues, newInput]);
      } else {
        setInputValues([newInput]);
      }
      setNewInput('');
    }
  };

  const handleRemoveInput = (index: number) => {
    const updatedInputs = [...inputValues];
    updatedInputs.splice(index, 1);
    setInputValues(updatedInputs);
  };

  return (
    <AddingInputDiv>
      <InputFieldDiv>
        <TextFieldWrapper
          label='Domain Name'
          variant='outlined'
          value={newInput}
          size='small'
          onChange={(event: any) => setNewInput(event.target.value)}
        />
        <Button
          variant='contained'
          sx={{
            background: 'primary',
            '&: hover': {
              background: 'primary',
            },
          }}
          onClick={handleAddInput}
        >
          <AddIcon sx={{ fontSize: '15px' }} />
          Add
        </Button>
      </InputFieldDiv>
      <MultipleInputsDiv>
        {inputValues?.map(
          (input, index) =>
            input && (
              <InputDiv key={index}>
                <span>{input}</span>
                <ClearIcon
                  sx={{ fontSize: '16px' }}
                  onClick={() => handleRemoveInput(index)}
                />
              </InputDiv>
            )
        )}
      </MultipleInputsDiv>
    </AddingInputDiv>
  );
};

export default MultipleInputs;
