import { Suspense, lazy } from 'react';
import Domain from '../components/Domain';
import LoadingSpinner from '../components/LoadingSpinner';
import Nav from '../components/Nav';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingSpinner />}>
    <Component {...props} />
  </Suspense>
)

const Statistics = Loadable(lazy(() => import('../components/Statistics')));

const routes: any[] = [
  {
    path: '/',
    element: <Nav />,
    children: [
      { path: '/', element: <Statistics /> },
      { path: '/domain', element: <Domain /> },
    ],
  },
];

export default routes;
