import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import MultipleInputs from './MultipleInputs';

interface AddRestrictedDomainProps {
  inputValues: string[];
  newInput: string;
  setNewInput: (newInput: string) => void;
  setInputValues: (inputValue: string[]) => void;
  setOpen: (open: boolean) => void;
  addDomainHandler: () => void;
}

const H2Style = styled('h2')({
  margin: 0,
});

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
});


const AddRestrictedDomain: React.FC<AddRestrictedDomainProps> = ({
  inputValues,
  newInput,
  setNewInput,
  setInputValues,
  setOpen,
  addDomainHandler,
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <H2Style>Add Restricted Domain</H2Style>
      <MultipleInputs
        inputValues={inputValues}
        setInputValues={setInputValues}
        newInput={newInput}
        setNewInput={setNewInput}
      />
      <ButtonContainer>
        <Button
          variant='contained'
          onClick={addDomainHandler}
          sx={{
            background: 'primary',
            '&:hover': {
              background: 'primary',
            },
          }}
        >
          Submit
        </Button>
        <Button
          variant='contained'
          onClick={() => setOpen(false)}
          sx={{
            background: 'primary',
            '&:hover': {
              background: 'primary',
            },
          }}
        >
          Close
        </Button>
      </ButtonContainer>
    </Box>
  );
};

export default AddRestrictedDomain;
