import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { fetchDomain, addDomain } from '../utils/domainControl';
import AddRestrictedDomain from './AddRestrictedDomain';

const TableHead = styled('th')(({theme}) => ({
  border: '1px solid black',
  width: '80vw',
  marginTop: '25px',
  height: '30px',
  textAlign: 'center',
  borderCollapse: 'collapse',
  padding: '7px',
  backgroundColor: '#ddf',
  color: theme.palette.primary.main,
}));

const ButtonWrapper = styled('button')(({ theme }) => ({
  padding: '0.5rem',
  borderRadius: '10px',
  border: 'none',
  backgroundColor: theme.palette.primary.main,
  fontSize: '16px',
  fontWeight: 600,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ButtonDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '2rem',
});

const TableDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const Table = styled('table')({
  border: '1px solid black',
  width: '40vw',
  marginTop: '25px',
  height: '30px',
  textAlign: 'center',
  borderCollapse: 'collapse',
  padding: '7px',

  '& tbody tr:nth-of-type(even)': {
    backgroundColor: '#f5f5f5',
  },
});

const TableData = styled('td')({
  border: '1px solid black',
  width: '80vw',
  marginTop: '25px',
  height: '30px',
  textAlign: 'center',
  borderCollapse: 'collapse',
  padding: '7px',
});

const Domain = () => {
  const tableHeading = ['id', 'Domain'];

  const [domain, setDomain] = useState([]);
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [newInput, setNewInput] = useState<string>('');

  const [open, setOpen] = useState(false);

  const handleClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const fetchRestrictedDomain = async () => {
    const data = await fetchDomain();
    if (data) {
      setDomain(data);
    } else {
      setDomain([]);
    }
  };

  useEffect(() => {
    fetchRestrictedDomain();
  }, []);

  const addDomainHandler = async () => {
    if( inputValues) {
    const response = await addDomain(inputValues);
    if (response?.message === 'Domain added successfully') {
      fetchRestrictedDomain();
    }
    setInputValues([]);
    setOpen(false);
  }
  };

  return (
    <>
      <TableDiv>
        <Table>
          <thead>
            <tr>
              {tableHeading.map((item, index) => (
                <TableHead key={index}>{item}</TableHead>
              ))}
            </tr>
          </thead>
          <tbody>
            {domain?.map((row: any, index: number) => (
              <tr key={row.id}>
                <TableData>{index + 1}</TableData>
                <TableData>{row.domain}</TableData>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableDiv>
      <ButtonDiv>
        <ButtonWrapper onClick={() => setOpen(true)}>
          <AddIcon /> Add Domain
        </ButtonWrapper>
      </ButtonDiv>
      <Modal open={open} onClose={handleClose}>
        <div>
          <AddRestrictedDomain
            addDomainHandler={addDomainHandler}
            newInput={newInput}
            setNewInput={setNewInput}
            inputValues={inputValues}
            setInputValues={setInputValues}
            setOpen={setOpen}
          />
        </div>
      </Modal>
    </>
  );
};

export default Domain;
